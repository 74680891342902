import {
  computed,
  Ref,
  shallowRef,
  watch,
  watchEffect,
} from '@vue/composition-api';
import { OSFamilies } from '@ax/data-services-devices/models/oses';
import { timespan } from '@ax/date-time-utils';
import {
  RemoteControlServiceStatusCode,
  RemoteControlServiceErrorCode,
  RemoteControlSocketError,
  useRemoteControlApiWebSocket,
  RemoteControlMetricsEvent,
} from '@ax/data-services-remote-control';

import { SegmentAnalytics } from '@ax/data-services-tracking/analytics';
import { consoleI18n } from '@ax/console-i18n';
import { errorReporter } from '@ax/error-reporter';
import { showWarningNotification, clearNotifications } from '@ax/notifications';

import { useRFB } from './useRFB';
import {
  RFBStatus,
  RFBError,
  AdditionalRemoteControlStatus,
} from '../models/remote-control';

const ONE_MINUTE = 60_000;
export const TIMEOUT_TIME = 5 * ONE_MINUTE;
const INSTALL_WAIT_NOTIFICATION_DURATION = 8000;

/**
 * Opens up a remote control session
 *
 * @param displayElement Ref<HTMLElement>
 * @param deviceId Ref<string>
 */
export function useRemoteControl(
  displayElement: Ref<HTMLElement>,
  orgId: Ref<number>,
  deviceUuid: Ref<string>,
  deviceOs: Ref<OSFamilies>,
) {
  const error = shallowRef<
    RemoteControlServiceErrorCode | RemoteControlSocketError | RFBError
  >();

  const rfb = useRFB(displayElement, deviceOs);
  const rcApiSocket = useRemoteControlApiWebSocket(
    orgId.value,
    deviceUuid.value,
  );
  let rfbConnectionTimer;
  let rcSessionId: string | undefined;

  const sessionMetricsMap = new Map<string, string | Date | boolean>();

  let timeout;
  let installWaitNotification;
  let installWaitNotificationCount = 0;
  const maxInstallWaitNotifications = 4;

  // Just in case timers were somehow not cleared by the rfb.status watcher
  const allowTimeout = computed(() => {
    return !!(
      !rfb.status.value || rfb.status.value === RFBStatus.uninitialized
    );
  });

  function setTimers() {
    clearTimers();
    timeout = setTimeout(() => {
      if (allowTimeout.value) {
        // Setting this error will trigger the disconnect
        error.value = RemoteControlServiceErrorCode.timeout;
      }
    }, TIMEOUT_TIME);

    // While the user waits, display notifications every minute
    installWaitNotification = setInterval(() => {
      installWaitNotificationCount += 1;

      if (
        allowTimeout.value &&
        installWaitNotificationCount <= maxInstallWaitNotifications
      ) {
        showWarningNotification(
          consoleI18n.t('remoteControl.waitingForInstall:notification'),
          INSTALL_WAIT_NOTIFICATION_DURATION,
        );
      } else {
        clearInterval(installWaitNotification);
      }
    }, ONE_MINUTE);
  }
  function clearTimers() {
    clearTimeout(timeout);
    clearInterval(installWaitNotification);
    installWaitNotificationCount = 0;
  }

  // Status starts as 'initializing',
  // then might move to 'installing',
  // then it will take its status from NoVNC (RFB),
  // and finally move to 'disconnected'
  const status = computed<RFBStatus | AdditionalRemoteControlStatus>(() => {
    if (
      rcApiSocket.error.value === RemoteControlSocketError.serviceUnavailable
    ) {
      return AdditionalRemoteControlStatus.unavailable;
    }
    if (
      rcApiSocket.error.value ===
      RemoteControlSocketError.serviceConnectionError
    ) {
      return AdditionalRemoteControlStatus.error;
    }
    if (
      rcApiSocket.error.value === RemoteControlSocketError.authenticationFailed
    ) {
      return AdditionalRemoteControlStatus.unauthorized;
    }
    // We retry auth two times, and in between the two attempts, the socket
    // is closed, but we don't want to show that to the end user
    if (rcApiSocket.isAuthenticating.value) {
      return AdditionalRemoteControlStatus.authenticating;
    }
    if (rcApiSocket.status.value === 'CLOSED') {
      return AdditionalRemoteControlStatus.disconnected;
    }
    if (
      rcApiSocket.data.value?.statusCode ===
      RemoteControlServiceStatusCode.moduleInstalling
    ) {
      return AdditionalRemoteControlStatus.installing;
    }
    if (
      rcApiSocket.data.value?.statusCode ===
      RemoteControlServiceStatusCode.consentRequested
    ) {
      return AdditionalRemoteControlStatus.awaitingConsent;
    }
    if (
      rcApiSocket.data.value?.statusCode ===
      RemoteControlServiceStatusCode.consentGranted
    ) {
      return AdditionalRemoteControlStatus.consentGranted;
    }
    if (rfb.status.value && rfb.status.value !== RFBStatus.uninitialized) {
      return rfb.status.value;
    }

    // After 'tunnelOpened', we receive no further statuses from RC API.
    // These conditions must come after rfb.status.value because
    // the status of our VNC/RFB connection picks up where the RC API leaves off.
    if (
      rcApiSocket.data.value?.statusCode ===
        RemoteControlServiceStatusCode.tunnelOpening ||
      rcApiSocket.data.value?.statusCode ===
        RemoteControlServiceStatusCode.tunnelOpened
    ) {
      return AdditionalRemoteControlStatus.tunnelOpening;
    }

    // This is the final fallback status when there is nothing else defined
    return AdditionalRemoteControlStatus.initializing;
  });

  watch(rcApiSocket.data, (newVal) => {
    if (newVal) {
      if (newVal.rcSessionId) {
        rcSessionId = newVal.rcSessionId;
        buildAndSendMetrics(RemoteControlMetricsEvent.sessionAuthorized);
      }

      errorReporter.addAction(`rc_api_data_${newVal.statusCode}`, {
        rcSessionId,
        deviceUuid: deviceUuid.value,
      });

      // Set a timeout for the time between authorized and consentRequested.
      // Set a another timeout for the time between tunnelOpening and tunnelOpened
      if (
        newVal.statusCode === RemoteControlServiceStatusCode.authorized ||
        newVal.statusCode === RemoteControlServiceStatusCode.tunnelOpening
      ) {
        setTimers();
        return;
      }

      // Installation was successful and we can clear the timers.
      // The BE will handle timeout for getting remote user consent and send 'consentDenied'
      // in case of a timeout.
      if (
        newVal.statusCode === RemoteControlServiceStatusCode.consentRequested
      ) {
        sessionMetricsMap.set(
          RemoteControlMetricsEvent.installSuccess,
          new Date(),
        );
        clearTimers();
        return;
      }

      // The tunnel was successfully opened and we can clear the timers
      // and connect to the tunnel
      if (
        newVal.statusCode === RemoteControlServiceStatusCode.tunnelOpened &&
        newVal.tunnelUrl &&
        newVal.vncPassword
      ) {
        if (!sessionMetricsMap.get(RemoteControlMetricsEvent.installSuccess)) {
          sessionMetricsMap.set(
            RemoteControlMetricsEvent.installSuccess,
            new Date(),
          );
        }
        clearTimers();
        rfb.connect(newVal.tunnelUrl, newVal.vncPassword);
        return;
      }

      // Errors automatically trigger a disconnect, which in turn runs clearTimers
      if (newVal.errorCode === RemoteControlServiceErrorCode.installFailed) {
        error.value = RemoteControlServiceErrorCode.installFailed;
        return;
      }
      if (newVal.statusCode === RemoteControlServiceStatusCode.consentDenied) {
        sessionMetricsMap.set(RemoteControlMetricsEvent.consentDenied, true);
        error.value = RemoteControlServiceErrorCode.consentDenied;
        return;
      }
      if (
        rfb.status.value !== RFBStatus.connected &&
        ([
          RemoteControlServiceStatusCode.tunnelClosing,
          RemoteControlServiceStatusCode.tunnelClosed,
        ].includes(newVal?.statusCode as RemoteControlServiceStatusCode) ||
          [
            RemoteControlServiceErrorCode.openChannelFailed,
            RemoteControlServiceErrorCode.closeChannelFailedRetrying,
            RemoteControlServiceErrorCode.closeChannelFailed,
          ].includes(newVal?.errorCode as RemoteControlServiceErrorCode))
      ) {
        error.value = RemoteControlServiceErrorCode.openChannelFailed;
        return;
      }

      // Handle unknown errors
      if (
        newVal.statusCode === RemoteControlServiceStatusCode.error &&
        rfb.status.value !== RFBStatus.connected
      ) {
        error.value =
          (newVal.errorCode as RemoteControlServiceErrorCode) ||
          RemoteControlServiceStatusCode.error;
      }
    }
  });

  watch(rcApiSocket.error, (newVal) => {
    error.value = newVal;
  });

  watch(rcApiSocket.status, (newVal, oldVal) => {
    // In case the RC API web socket is closed unexpectedly
    if (
      rfb.status.value !== RFBStatus.connected &&
      oldVal !== 'UNINITIALIZED' &&
      newVal === 'CLOSED' &&
      !sessionMetricsMap.get(RemoteControlMetricsEvent.sessionEnd)
    ) {
      disconnect();
    }
  });

  watch(rfb.error, (newVal) => {
    error.value = newVal;
  });

  watch(rfb.status, (newVal) => {
    errorReporter.addAction(`rc_rfb_status_${newVal}`, {
      ...(rfb.connectionAttempts.value
        ? { connectionAttempts: rfb.connectionAttempts.value }
        : {}),
      rcSessionId,
      deviceUuid: deviceUuid.value,
    });

    if (newVal === RFBStatus.connected) {
      clearTimers();
      clearNotifications();

      sessionMetricsMap.set(
        RemoteControlMetricsEvent.connectSuccess,
        new Date(),
      );
      buildAndSendMetrics(RemoteControlMetricsEvent.connectSuccess);
      rfbConnectionTimer = setTimeout(() => {
        buildAndSendMetrics(RemoteControlMetricsEvent.oneMinuteConnectionTime);
      }, ONE_MINUTE);
    } else {
      clearTimeout(rfbConnectionTimer);
    }
  });

  const userFacingError = computed(() => {
    if (!error.value) {
      return undefined;
    }

    switch (error.value) {
      case RemoteControlServiceErrorCode.openChannelFailed:
      case RemoteControlServiceErrorCode.installFailed:
      case RemoteControlServiceErrorCode.timeout:
        return consoleI18n.t('remoteControl.setupFailed:notification');
      case RemoteControlSocketError.serviceUnavailable:
      case RemoteControlSocketError.serviceConnectionError:
      case RFBError.connectionFailure:
      case RFBError.credentialsRequired:
      case RFBError.securityFailure:
      case RFBError.unexpectedDisconnection:
        return consoleI18n.t('remoteControl.connectionFailed:notification');
      case RemoteControlServiceErrorCode.consentDenied:
        return consoleI18n.t('remoteControl.consentDenied:notification');
      case RemoteControlSocketError.authenticationFailed:
        return consoleI18n.t('remoteControl.authenticationFailed:notification');
      default:
        return consoleI18n.t('general.notifications.genericError');
    }
  });

  watchEffect(() => {
    if (error.value) {
      sessionMetricsMap.set(
        RemoteControlMetricsEvent.sessionError,
        error.value?.toString() || '',
      );
      errorReporter.addError(error.value, {
        rcSessionId,
        deviceUuid: deviceUuid.value,
      });
      disconnect();
    }
  });

  async function connect() {
    sessionMetricsMap.clear();
    sessionMetricsMap.set(RemoteControlMetricsEvent.sessionStart, new Date());
    rcSessionId = undefined; // Will be populated from a message received from rc api websocket after authentication
    buildAndSendMetrics(RemoteControlMetricsEvent.sessionStart);
    error.value = undefined;
    if (
      rcApiSocket.status.value === 'OPEN' ||
      rfb.status.value === RFBStatus.connected
    ) {
      await disconnect();
    }
    rfb.reset();
    return rcApiSocket.open();
  }

  async function disconnect() {
    sessionMetricsMap.set(RemoteControlMetricsEvent.sessionEnd, new Date());
    buildAndSendMetrics(RemoteControlMetricsEvent.sessionEnd);
    clearTimers();

    // Clear the clipboard on the remote device on disconnect
    await rfb.clearClipboard();

    // If user closes window, this code won't end up running,
    // but sockets will disconnect anyway from the window closing
    rfb.disconnect();
    rcApiSocket.close();
  }

  function buildAndSendMetrics(segmentEventName: RemoteControlMetricsEvent) {
    const now = new Date();
    const sessionStartTime = sessionMetricsMap.get(
      RemoteControlMetricsEvent.sessionStart,
    ) as Date;
    const sessionEndTime = sessionMetricsMap.get(
      RemoteControlMetricsEvent.sessionEnd,
    ) as Date;
    const errorMessage = sessionMetricsMap.get(
      RemoteControlMetricsEvent.sessionError,
    );
    const consentDenied = sessionMetricsMap.get(
      RemoteControlMetricsEvent.consentDenied,
    );

    const durationFromStartToInstalled = timespan(
      sessionStartTime,
      sessionMetricsMap.get(RemoteControlMetricsEvent.installSuccess) as Date,
    );

    const durationFromStartToConnected = timespan(
      sessionStartTime,
      sessionMetricsMap.get(RemoteControlMetricsEvent.connectSuccess) as Date,
    );

    const durationConnected = timespan(
      sessionMetricsMap.get(RemoteControlMetricsEvent.connectSuccess) as Date,
      now,
    );

    const durationTotal = timespan(sessionStartTime, now);

    SegmentAnalytics.track(segmentEventName, {
      startTime: sessionStartTime?.toISOString(),
      ...(sessionEndTime ? { endTime: sessionEndTime.toISOString() } : {}),
      durationTotal,
      durationConnected,
      durationFromStartToInstalled,
      durationFromStartToConnected,
      rcSessionId,
      ...(errorMessage ? { errorMessage } : {}),
      ...(consentDenied ? { consentDenied } : {}),
      vncTunnelConnectAttempts: rfb.connectionAttempts.value,
      deviceUuid: deviceUuid.value,
    });
  }

  return {
    connect,
    disconnect,
    pasteToRemote: rfb.paste,
    sendCtrlAltDel: rfb.sendCtrlAltDel,
    lastCopiedFromRemote: rfb.lastCopiedFromRemote,
    status,
    error,
    userFacingError,
  };
} // useRemoteControl()
