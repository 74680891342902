import { Ref, shallowRef, shallowReactive } from '@vue/composition-api';
import RFB from '@novnc/novnc/core/rfb';
import KeyTable from '@novnc/novnc/core/input/keysym';
import { OSFamilies } from '@ax/data-services-devices/models';
import {
  RFBStatus,
  RFBError,
  RFBEvents,
  PasteStrategy,
} from '../models/remote-control';

// RFB refers to the 'Remote Framebuffer' protocol. It is the protocol
// used in VNC for remote access to graphical user interfaces

interface RFBDeviceResolution {
  width?: number;
  height?: number;
}

interface RFBDisconnectEvent extends CustomEvent {
  detail: {
    // In the event of an unexpected termination or an error, clean will be set to false
    // (@documentation: https://novnc.com/noVNC/docs/API.html#disconnect)
    clean: boolean;
  };
}

interface RFBClipboardEvent extends CustomEvent {
  detail: {
    text: string;
  };
}

interface RFBSecurityFailureEvent extends CustomEvent {
  detail: {
    status: number;
    reason?: string;
  };
}

interface RFBCredentialsRequiredEvent extends CustomEvent {
  detail: {
    types: Array<string>;
  };
}

function replaceCurlyQuotes(text: string) {
  return text.replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');
}

export function useRFB(
  displayElement: Ref<HTMLElement>,
  deviceOS: Ref<OSFamilies>,
) {
  let rfb: RFB | undefined;

  const status = shallowRef<RFBStatus>(RFBStatus.uninitialized);
  const error = shallowRef<RFBError>();
  let shouldRetry = true;
  let initialTimer;
  let retryTimer;
  const connectionAttempts = shallowRef<number>(0);
  let purposefulDisconnect = false;
  const maxConnectionAttempts = 40;

  const resolution = shallowReactive<RFBDeviceResolution>({
    width: 0,
    height: 0,
  });

  const lastCopiedFromRemote = shallowRef<string>();

  // Copy text content to remote device clipboard and optionally
  // initiate the pasting of that content as well within the remote device
  function paste(text: string, strategy?: PasteStrategy) {
    const processedText = replaceCurlyQuotes(text);
    rfb?.clipboardPasteFrom(processedText);
    setTimeout(() => {
      if (strategy === PasteStrategy.paste) {
        if (deviceOS.value === OSFamilies.mac) {
          rfb?.sendKey(KeyTable.XK_Meta_L, 'MetaLeft', true);
          rfb?.sendKey(KeyTable.XK_v, 'v');
          rfb?.sendKey(KeyTable.XK_Meta_L, 'MetaLeft', false);
        } else {
          rfb?.sendKey(KeyTable.XK_Control_L, 'ControlLeft', true);
          rfb?.sendKey(KeyTable.XK_v, 'v');
          rfb?.sendKey(KeyTable.XK_Control_L, 'ControlLeft', false);
        }
      } else if (strategy === PasteStrategy.keystrokes) {
        const canvasEl = displayElement.value.querySelector('canvas');
        if (canvasEl) {
          processedText.split('').forEach((char) => {
            canvasEl.dispatchEvent(new KeyboardEvent('keydown', { key: char }));
            canvasEl.dispatchEvent(new KeyboardEvent('keyup', { key: char }));
          });
        }
      }
    }, 250);
  }

  async function clearClipboard() {
    // We need to give clipboardPasteFrom some time before proceeding
    // to disconnect from the VNC session
    rfb?.clipboardPasteFrom(' ');
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  function sendCtrlAltDel() {
    rfb?.sendCtrlAltDel();
  }

  function initialConnect(tunnelUrl: string, vncPassword: string) {
    purposefulDisconnect = false;
    connectionAttempts.value = 0;
    shouldRetry = true;

    status.value = RFBStatus.connecting;
    initialTimer = setTimeout(() => {
      initialTimer = undefined;
      connect(tunnelUrl, vncPassword);
    }, 5000);
  }

  function connect(tunnelUrl: string, vncPassword: string) {
    error.value = undefined;
    connectionAttempts.value += 1;

    rfb = new RFB(displayElement.value, tunnelUrl, {
      shared: true,
      credentials: {
        password: vncPassword,
      },
    });

    status.value = RFBStatus.connecting;

    // Optional RFB parameters (documentation: https://novnc.com/noVNC/docs/API.html#properties)
    rfb.background = 'transparent';
    rfb.resizeSession = true;
    rfb.scaleViewport = true;

    rfb.addEventListener(RFBEvents.connect, () => {
      resolution.width = rfb?._fbWidth;
      resolution.height = rfb?._fbHeight;
      status.value = RFBStatus.connected;
      shouldRetry = false;
    });

    rfb.addEventListener(RFBEvents.disconnect, (e: RFBDisconnectEvent) => {
      // If the console user purposefully used the disconnect button
      // or if the VNC server on the remote device purposefully ends the session
      if (purposefulDisconnect || e.detail.clean) {
        status.value = RFBStatus.disconnected;
        return;
      }

      // Otherwise, if an active connection was disconnected unexpectedly,
      // then we can display an error
      if (status.value === RFBStatus.connected) {
        error.value = RFBError.unexpectedDisconnection;
        status.value = RFBStatus.disconnected;
        return;
      }

      // If the initial connection attempt failed, we can retry
      if (
        status.value === RFBStatus.connecting &&
        shouldRetry &&
        connectionAttempts.value < maxConnectionAttempts
      ) {
        // retry connection
        reconnect(tunnelUrl, vncPassword);
      } else {
        status.value = RFBStatus.disconnected;
        error.value = RFBError.connectionFailure;
      }
    });

    rfb.addEventListener(
      RFBEvents.securityfailure,
      (e: RFBSecurityFailureEvent) => {
        status.value = RFBStatus.disconnected;
        error.value = RFBError.securityFailure;
        console.error('[rfb security failure]', e.detail);
      },
    );

    rfb.addEventListener(
      RFBEvents.credentialsrequired,
      (e: RFBCredentialsRequiredEvent) => {
        status.value = RFBStatus.disconnected;
        error.value = RFBError.credentialsRequired;
        console.error('[rfb credentials required]', e.detail);
      },
    );

    rfb.addEventListener(RFBEvents.clipboard, (e: RFBClipboardEvent) => {
      lastCopiedFromRemote.value = e.detail.text;
    });

    return rfb;
  } // connect()

  function disconnect() {
    if (status.value === RFBStatus.uninitialized) {
      return;
    }
    if (initialTimer || retryTimer) {
      clearTimeout(initialTimer);
      clearTimeout(retryTimer);
      status.value = RFBStatus.disconnected;
    }
    if (
      rfb &&
      (status.value === RFBStatus.connecting ||
        status.value === RFBStatus.connected)
    ) {
      purposefulDisconnect = true;
      rfb.disconnect();
    } else {
      status.value = RFBStatus.disconnected;
    }
  }

  function reconnect(tunnelUrl: string, vncPassword: string) {
    retryTimer = setTimeout(() => {
      retryTimer = undefined;
      rfb = undefined;
      connect(tunnelUrl, vncPassword);
    }, 5000);
  }

  function reset() {
    rfb = undefined;
    connectionAttempts.value = 0;
    status.value = RFBStatus.uninitialized;
  }

  function getRfbInstance() {
    return rfb;
  }

  return {
    status,
    error,
    resolution,
    clearClipboard,
    lastCopiedFromRemote,
    paste,
    connectionAttempts,
    connect: initialConnect,
    disconnect,
    reset,
    sendCtrlAltDel,
    getRfbInstance,
  };
} // useRFB()
