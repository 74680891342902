var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"remote-wrapper d-flex flex-column fill-height",class:{ 'remote-wrapper--connected': _vm.status === 'connected' }},[_c('div',{staticClass:"remote-toolbar flex-shrink-1"},[_c('div',{staticClass:"align-self-center pl-3"},[(!_vm.statusInPlaceholder)?_c('ax-remote-control-status',{attrs:{"status":_vm.status}}):_vm._e()],1),(_vm.hasInitiatedConnect)?_c('div',{class:[
        'remote-actions',
        { 'remote-actions--disconnected': _vm.status === 'disconnected' } ]},[(_vm.canTakeAction && _vm.status === 'disconnected')?_c('ax-button',{staticClass:"mt-2",attrs:{"mode":"primary","data-test-id":"reconnect-button"},on:{"click":_vm.connect}},[_vm._v(" "+_vm._s(_vm.$t('remoteControl.reconnect:button'))+" ")]):_vm._e(),(_vm.isFullscreen && _vm.status !== 'connected')?_c('ax-button',{staticClass:"mt-2 ml-2",attrs:{"mode":"tertiary","x-small":""},on:{"click":_vm.toggleFullscreen}},[_vm._v(" "+_vm._s(_vm.$t('remoteControl.exitFullscreen:button'))+" ")]):_vm._e(),(_vm.canTakeAction && _vm.status !== 'disconnected')?[_c('ax-tooltip',{attrs:{"bottom":"","data-test-id":"disconnect-button"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({attrs:{"aria-label":_vm.$t('remoteControl.disconnect:button')},on:{"click":_vm.modals.confirmDisconnect.open}},'button',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiPower))])],1)]}}],null,false,2411284394)},[_c('span',[_vm._v(_vm._s(_vm.$t('remoteControl.disconnect:button')))])]),(_vm.status === 'connected')?[_c('ax-tooltip',{attrs:{"bottom":"","data-test-id":"clipboard-button"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({attrs:{"aria-label":_vm.$t('remoteControl.clipboard:phrase')},on:{"click":_vm.toggleSharedClipboard}},'button',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiClipboardText))])],1)]}}],null,false,2452704234)},[_c('span',[_vm._v(_vm._s(_vm.$t('remoteControl.clipboard:phrase')))])]),(_vm.deviceOs === 'Windows')?_c('ax-menu',{attrs:{"auto":"","data-test-id":"special-key-commands-button"},scopedSlots:_vm._u([{key:"activator",fn:function(menu){return [_c('v-tooltip',{staticClass:"ax-tooltip",attrs:{"bottom":"","content-class":"ax-tooltip--content bottom","nudge-bottom":"4px"},scopedSlots:_vm._u([{key:"activator",fn:function(tooltip){return [_c('button',_vm._g({attrs:{"aria-label":_vm.$t('remoteControl.specialKeyCommands:button')},on:{"click":tooltip.on.mouseleave}},Object.assign({}, menu.on, tooltip.on)),[_c('v-icon',[_vm._v(_vm._s(_vm.mdiKeyboardOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('remoteControl.specialKeyCommands:button'))+" ")])]}}],null,false,2798609217),model:{value:(_vm.showKeyCommandMenu),callback:function ($$v) {_vm.showKeyCommandMenu=$$v},expression:"showKeyCommandMenu"}},[_c('v-list-item',{on:{"click":_vm.sendCtrlAltDel}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('remoteControl.ctrlAltDel:button'))+" ")])],1)],1):_vm._e(),_c('ax-tooltip',{attrs:{"bottom":"","data-test-id":"fullscreen-button"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('button',_vm._g(_vm._b({attrs:{"aria-label":_vm.fullscreenLabel},on:{"click":_vm.toggleFullscreen}},'button',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.fullscreenIcon))])],1)]}}],null,false,3460404539)},[_c('span',[_vm._v(_vm._s(_vm.fullscreenLabel))])])]:_vm._e()]:_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showSharedClipboard),expression:"showSharedClipboard"}],staticClass:"shared-clipboard"},[_c('ax-textarea',{ref:"sharedClipboardTextarea",staticClass:"mb-0",attrs:{"label":_vm.$t('remoteControl.pasteTextHere:label'),"hint":_vm.$t('remoteControl.paste:description'),"rows":"2"},nativeOn:{"paste":function($event){return _vm.closeClipboardAfterDelay.apply(null, arguments)}},model:{value:(_vm.sharedClipboard),callback:function ($$v) {_vm.sharedClipboard=$$v},expression:"sharedClipboard"}}),_c('div',{staticClass:"mt-2 d-flex mini-checkbox"},[_c('ax-checkbox',{staticClass:"mb-0",attrs:{"label":_vm.$t("remoteControl.pasteViaKeystrokes:label")},model:{value:(_vm.pasteViaKeystrokes),callback:function ($$v) {_vm.pasteViaKeystrokes=$$v},expression:"pasteViaKeystrokes"}}),_c('ax-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-2",attrs:{"tabindex":"0","aria-hidden":"false","aria-label":_vm.$t("remoteControl.pasteViaKeystrokes:description")}},on),[_vm._v(_vm._s(_vm.mdiInformationOutline))])]}}],null,false,2332258370)},[_vm._v(" "+_vm._s(_vm.$t("remoteControl.pasteViaKeystrokes:description"))+" ")])],1)],1)],2):_vm._e(),_c('div',{staticClass:"align-self-center pr-3"},[(!_vm.statusInPlaceholder)?_c('p',{staticClass:"text-small text-right ax-text-medium mb-0"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRemoteDesktop))]),_c('span',{attrs:{"data-test-id":"remote-device-name"}},[_vm._v(_vm._s(_vm.deviceName))])],1):_vm._e()])]),_c('div',{staticClass:"flex-grow-1 p-relative",on:{"click":_vm.closeTools}},[_c('div',{staticClass:"remote-content",on:{"focusin":_vm.closeTools}},[_c('div',{ref:"remote-screen",staticClass:"fill-height",attrs:{"data-test-id":"remote-screen"}})]),(_vm.statusInPlaceholder)?_c('div',{staticClass:"remote-content"},[_c('div',{staticClass:"fill-height d-flex flex-column align-center justify-center"},[_c('h1',{staticClass:"ml-5 mb-1 pb-0"},[_vm._v(" "+_vm._s(_vm.$t('remoteControl.automoxRemoteControl:heading'))+" ")]),_c('p',{staticClass:"mb-10 text-xl ax-text-medium",attrs:{"data-test-id":"remote-device-name"}},[_vm._v(" "+_vm._s(_vm.deviceName)+" ")]),(_vm.hasInitiatedConnect)?_c('ax-remote-control-status',{attrs:{"status":_vm.status}}):_c('ax-button',{staticClass:"mt-2",attrs:{"mode":"primary","data-test-id":"connect-button"},on:{"click":_vm.initiateConnect}},[_vm._v(" "+_vm._s(_vm.$t('remoteControl.connect:button'))+" ")])],1)]):_vm._e()]),_c('ax-modal',{model:{value:(_vm.modals.confirmDisconnect.opened),callback:function ($$v) {_vm.$set(_vm.modals.confirmDisconnect, "opened", $$v)},expression:"modals.confirmDisconnect.opened"}},[_c('ax-remote-control-disconnect-confirm-modal',{on:{"close-modal":_vm.modals.confirmDisconnect.close,"modal-confirm":function($event){_vm.disconnect();
        _vm.modals.confirmDisconnect.close();}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }