import type { Ref } from '@vue/composition-api';
import type { WebSocketStatus } from '@vueuse/core';

export enum RemoteControlServiceStatusCode {
  authorized = 'authorized',
  unauthorized = 'unauthorized',
  accepted = 'accepted',
  moduleInstalling = 'moduleInstalling',
  consentRequested = 'consentRequested',
  consentGranted = 'consentGranted',
  consentDenied = 'consentDenied',
  tunnelOpening = 'tunnelOpening',
  tunnelOpened = 'tunnelOpened',
  tunnelClosing = 'tunnelClosing',
  tunnelClosed = 'tunnelClosed',
  error = 'error',
  pong = 'pong',
}

export enum RemoteControlServiceErrorCode {
  openChannelFailed = 'openChannelFailed',
  closeChannelFailed = 'closeChannelFailed',
  installFailed = 'installFailed',
  genericError = 'genericError',
  closeChannelFailedRetrying = 'closeChannelFailedRetrying',
  timeout = 'timeout',
  consentDenied = 'consentDenied',
}

export enum RemoteControlSocketError {
  authenticationFailed = 'authenticationFailed',
  serviceUnavailable = 'serviceUnavailable',
  serviceConnectionError = 'serviceConnectionError',
}

export enum RemoteControlSocketCommand {
  authenticate = 'authenticate',
  startRemoteControl = 'startRemoteControl',
  ping = 'ping',
}

export interface RemoteControlSocketIncomingMessage {
  statusCode: `${RemoteControlServiceStatusCode}`;
  errorCode?: `${RemoteControlServiceErrorCode}`;
  tunnelUrl?: string;
  vncPassword?: string;
  rcSessionId?: string;
}

export interface RemoteControlSocketOutgoingMessage {
  command: `${RemoteControlSocketCommand}`;
  deviceUuid: string;
}

export type RemoteControlSocketWrapperStatus =
  | WebSocketStatus
  | 'UNINITIALIZED';
export interface RemoteControlSocketWrapper {
  status: Ref<RemoteControlSocketWrapperStatus>;
  isAuthenticating: Ref<boolean>;
  data: Ref<RemoteControlSocketIncomingMessage | undefined>;
  open: () => Promise<void>;
  send: (data: string, useBuffer?: boolean | undefined) => Promise<boolean>;
  close: () => Promise<void>;
  error: Ref<RemoteControlSocketError | undefined>;
}

export enum RemoteControlMetricsEvent {
  sessionAuthorized = 'Remote Control Session Authorized',
  sessionStart = 'Remote Control Session Start',
  installSuccess = 'Successful Install',
  consentDenied = 'Consent Denied',
  connectSuccess = 'Remote Control Successful Connection',
  oneMinuteConnectionTime = 'Remote Control 1 Minute Connection Time',
  sessionError = 'Session Error',
  sessionEnd = 'Remote Control Session End',
}
