import { watch, shallowRef } from '@vue/composition-api';
import { mintJwt, jwtIsExpired } from '@ax/data-services-authentication';
import { getConfig } from '@ax/data-services-config';
import { useWebSocket, WebSocketStatus } from '@vueuse/core';
import {
  RemoteControlSocketWrapper,
  RemoteControlServiceStatusCode,
  RemoteControlSocketCommand,
  RemoteControlSocketError,
} from '../models/remote-control-web-socket';

/**
 * Ensures web socket urls have the right protocol prefix
 * Note: ws:// will only be used if incoming url already uses it, otherwise using wss://
 * @param url may or may not have a web socket protocol
 * @returns url with either ws:// or wss:// protocol
 */
function withWebSocketProtocol(url: string) {
  return url.match(/^wss?:\/\//) ? url : `wss://${url.split('//').pop()}`;
}

export function useRemoteControlApiWebSocket(
  orgId: number,
  deviceUuid: string,
) {
  // This composition returns a 'socket' wrapper object
  // but it is not functional until after the getConfig promise resolves
  // and VueUse's useWebSocket composition gives us meaningful functions to run.
  let setSocketWrapperAsReady;
  const socketWrapperReady = new Promise((resolve) => {
    setSocketWrapperAsReady = resolve;
  });

  // These will be assigned their functions provided by `useWebSocket` once they are available.
  let openSocket;
  let sendToSocket;
  let closeSocket;

  const socket: RemoteControlSocketWrapper = {
    status: shallowRef('UNINITIALIZED'),
    isAuthenticating: shallowRef(false),
    data: shallowRef(),
    async open() {
      await socketWrapperReady;
      return openSocket();
    },
    async send(data: string | ArrayBuffer | Blob, useBuffer?: boolean) {
      await socketWrapperReady;
      return sendToSocket(data, useBuffer);
    },
    async close() {
      await socketWrapperReady;
      return closeSocket();
    },
    error: shallowRef(),
  };

  let cachedJwt: string;
  let authFailedCount = 0;
  let isRetryingAuth = false;

  getConfig().then(({ remote_control }) => {
    if (!remote_control?.url) {
      socket.error.value = RemoteControlSocketError.serviceUnavailable;
      throw Error(
        'The URL for remote control was not provided by the config service.',
      );
    }
    const { status, open, send, close } = useWebSocket(
      withWebSocketProtocol(remote_control.url),
      {
        immediate: false,
        autoClose: false,
        heartbeat: {
          // Send a 'ping' command every two minutes as a safety measure for keeping the ws connection alive
          // (server responds with a 'pong' statusCode )
          message: JSON.stringify({ command: RemoteControlSocketCommand.ping }),
          interval: 120_000,
        },
        autoReconnect: false,
        onMessage: receiveMessage,
        onConnected: () => {
          send(
            JSON.stringify({
              command: RemoteControlSocketCommand.authenticate,
              token: cachedJwt,
            }),
          );
        },
        onDisconnected: () => {
          if (isRetryingAuth) {
            getJwtAndOpenSocket();
          }
        },
        onError: () => {
          socket.isAuthenticating.value = false;
          socket.error.value = RemoteControlSocketError.serviceConnectionError;
        },
      },
    );

    // Make the useWebSocket methods available to the socket wrapper object
    openSocket = () => {
      authFailedCount = 0;
      isRetryingAuth = false;
      getJwtAndOpenSocket();
    };
    sendToSocket = send;
    closeSocket = () => {
      socket.isAuthenticating.value = false;
      close();
    };

    // Now that the socket wrapper object has real methods populated,
    // we can resolve the 'socketWrapperReady' promise
    setSocketWrapperAsReady();

    function receiveMessage(_ws, event) {
      const parsedMessage = JSON.parse(event.data);
      const { statusCode } = parsedMessage;

      // Ignore heartbeat
      if (statusCode === RemoteControlServiceStatusCode.pong) {
        return;
      }

      // Ensure tunnelUrl has Web Socket protocol
      if (parsedMessage.tunnelUrl) {
        parsedMessage.tunnelUrl = withWebSocketProtocol(
          parsedMessage.tunnelUrl,
        );
      }

      socket.data.value = parsedMessage;

      if (statusCode === RemoteControlServiceStatusCode.authorized) {
        completeAuthAndStartRemoteControl();
        return;
      }

      if (statusCode === RemoteControlServiceStatusCode.unauthorized) {
        authFailedCount += 1;

        if (authFailedCount === 1) {
          retryAuth();
        } else {
          // If auth fails a 2nd time, we'll give up
          abandonAuth();
        }
      }
    }

    // Mint a new JWT (or use unexpired cached JWT) and open the socket.
    // (The JWT is sent by the onConnected handler)
    async function getJwtAndOpenSocket() {
      socket.isAuthenticating.value = true;
      if (!isRetryingAuth && cachedJwt && !jwtIsExpired(cachedJwt)) {
        open();
      } else {
        try {
          cachedJwt = await mintJwt(
            {
              orgId,
              deviceUuid,
            },
            { loaderEnabled: false },
          );
          open();
        } catch (e) {
          socket.error.value = RemoteControlSocketError.authenticationFailed;
          socket.isAuthenticating.value = false;
          close();
          throw e;
        }
      }
    }

    function retryAuth() {
      isRetryingAuth = true;
      close();
    }

    function abandonAuth() {
      isRetryingAuth = false;
      socket.error.value = RemoteControlSocketError.authenticationFailed;
      close();
      setTimeout(() => {
        // Allows us to continue displaying 'Authenticating' as a status
        // until the connection is closed
        socket.isAuthenticating.value = false;
      }, 500);
    }

    function completeAuthAndStartRemoteControl() {
      isRetryingAuth = false;
      authFailedCount = 0;
      setTimeout(() => {
        // Prevents the status from changing too quickly before the user can read it
        socket.isAuthenticating.value = false;
      }, 1000);
      send(
        JSON.stringify({
          command: RemoteControlSocketCommand.startRemoteControl,
        }),
      );
    }

    watch(
      status,
      (newVal: WebSocketStatus) => {
        socket.status.value = newVal;
      },
      { immediate: true },
    );
  });

  return socket;
}
