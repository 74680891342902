export enum RFBEvents {
  connect = 'connect',
  disconnect = 'disconnect',
  credentialsrequired = 'credentialsrequired',
  securityfailure = 'securityfailure',
  clipboard = 'clipboard',
  bell = 'bell',
  desktopname = 'desktopname',
  capabilities = 'capabilities',
}

export enum RFBStatus {
  uninitialized = 'uninitialized',
  connecting = 'connecting',
  connected = 'connected',
  disconnecting = 'disconnecting',
  disconnected = 'disconnected',
}

export enum RFBError {
  connectionFailure = 'connectionFailure',
  credentialsRequired = 'credentialsRequired',
  securityFailure = 'securityFailure',
  unexpectedDisconnection = 'unexpectedDisconnection',
}

export enum AdditionalRemoteControlStatus {
  initializing = 'initializing',
  authenticating = 'authenticating',
  installing = 'installing',
  awaitingConsent = 'awaitingConsent',
  consentGranted = 'consentGranted',
  tunnelOpening = 'tunnelOpening',
  disconnected = 'disconnected',
  unauthorized = 'unauthorized',
  unavailable = 'unavailable',
  error = 'error',
}

export enum PasteStrategy {
  paste = 'paste',
  keystrokes = 'keystrokes',
}
